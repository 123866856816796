@import "../variables";

.login-pages {
  .head-note {
    p {
      margin-bottom: 0;
      line-height: 1.7;
    }
  }

  .btn-login, .btn-forgot {
    min-width: 256px;
    max-width: 256px;
    line-height: 1.2;
    padding-top: .7rem;
    padding-bottom: .7rem;
  }

  .btn-login {
    line-height: 2;
  }
}

@media screen and(max-width: 768px) {
  .login-pages {
    .head-note {
      font-weight: normal !important;
      text-align: center;

      p {
        line-height: 1.5;
      }
    }

    .form-group {
      .badge-required {
        line-height: 1.5;
        white-space: nowrap;

        span {
          display: inline;
        }

        br {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 376px) {
  p.row.justify-content-center {
    padding: 0 1rem;
  }
}